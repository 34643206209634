/* BELOW 864 px */
@media (max-width: 54em) {
	/* main {
		width: 100%;
	} */

	iframe {
		max-width: 40rem;
		margin: 0 auto;
	}
}

/* NEEDED FOR HEADER LATER */
/* BELOW 560px */
@media (max-width: 35em) {
	iframe {
		max-width: 30rem;
	}

	/* main {
		margin: 4.8rem auto 4.8rem auto;
	}
	
	.subsection--listen-on {
		padding: 2.4rem 0 2.4rem 0;
	}

	.section--extra-content .subheading {
		margin: 2.4rem 0 2.4rem 0;
	}

	.section--extra-content .text-button-container {
		padding: 0.6rem 0 2.4rem 0;
	}

	#newsletter-form .subheading {
		margin: 2.4rem 0 2.4rem 0;
	} */
}

/* BELOW 352px */
@media (max-width: 22em) {
	main {
		padding: 4.8rem 0;
		margin: 0rem auto;
	}

	iframe {
		max-width: 27.5rem;
	}

	#input-container {
		margin: 0 2rem;
	}

	#email-input,
	#submit-button {
		padding: 1rem 1.25rem;
		/* width: 20rem; */
	}
}
